<template lang="pug">
include ../../../configs/template
form().row.mt-4
  div(v-if="!$route.params.documentID").col-sm-12
    +select-validation('body.rank', 'availableRanksList', '', 'nameLang', '["required"]')(
      @input="(item) => enterDoublePosition(item, body.list_positions) + checkExistProtocolSQC(item)"
      :placeholder="$t('qualification') + ' - ' + $t('rank')"
      :label="$t('qualification') + ' - ' + $t('rank')"
      return-object)
  div(v-if="!$route.params.documentID").col-sm-12
    +select-validation('body.list_positions', 'listPosition', 'position', 'nameLang', '["required"]')(multiple)
  div.col-sm-12
    +select-validation('body.port', 'listPort', 'port', 'nameLang', '["required"]')
  div(v-if="body.rank?.is_dkk && !$route.params.documentID").col-sm-12
    +select-validation('body.protocol_dkk', 'listProtocol', 'protocol', 'nameLang', '["required"]')
  div.col-sm-12
    FileDropZone(ref="mediaContent")
  div.col-sm-12
    v-btn(color="success" @click="saveStatement" :loading="isLoading") {{ $t('btn.save') }}
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { sailor } from '@/mixins/validationRules'
import { mappingPositions, enterDoublePosition, clearBody } from '@/mixins/main'
import { SUCCESS_CODE } from '@/configs/constants'

const initBody = () => ({
  rank: null,
  list_positions: [],
  port: null,
  protocol_dkk: null
})
export default {
  name: 'FormCreateEditQualificationStatement',
  components: {
    FileDropZone: () => import('@/components/atoms/DropZone/DropZone.vue')
  },
  props: {
    statement: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      enterDoublePosition,
      mappingPositions,
      body: initBody(),
      isLoading: false,
      listProtocol: []
    }
  },
  computed: {
    ...mapState({
      nameLang: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr'
    }),
    ...mapGetters(['protocolsByRank', 'availableRanksList', 'portsActual']),
    listPosition () {
      return mappingPositions(this.body.rank, true)
    },
    listPort () {
      return this.portsActual(false)
    }
  },
  validations () { return sailor.qualification.statement(this) },
  mounted () {
    if (this.$route?.params?.documentID) this.body.port = this.statement.port?.id || this.statement.port
  },
  methods: {
    ...mapActions(['setQualificationStatement', 'updateQualificationStatement']),
    async saveStatement () {
      if (this.$v.$invalid) return this.$v.$touch()
      this.isLoading = true
      let data = {
        ...this.$route.params,
        body: { ...clearBody({ ...this.body }) },
        media: {
          files: [...this.$refs.mediaContent.filesArray],
          file_type: 'statement_qualification_documnet'
        }
      }
      if (!this.$route?.params?.documentID) {
        data.body.sailor = this.$route.params.id
        data.body.rank = this.body.rank.id
      }
      const response = await this[`${this.$route.params?.documentID ? 'update' : 'set'}QualificationStatement`](data)
      if (SUCCESS_CODE.includes(response.code)) {
        !this.$route.params?.documentID
          ? this.$notification.success('addedQualificationStatement')
          : this.$notification.success('editQualificationStatement')
        this.body = initBody()
        this.$v.$reset()
        this.$parent.isViewAddSlot = false
      }
      this.isLoading = false
    },
    checkExistProtocolSQC (rank) {
      if (rank && rank.is_dkk) {
        this.protocolView = true
        this.listProtocol = this.protocolsByRank(rank.id)
        if (this.listProtocol.length) {
          this.$notification.success('FoundSQC')
          if (this.listProtocol.length === 1) this.body.protocol_dkk = this.listProtocol[0].id
        } else {
          this.$notification.warning('NotFoundSQC')
        }
      }
    }
  }
}
</script>
